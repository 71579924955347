import * as React from "react";
import {
  PrismicPreviewProvider,
} from "gatsby-plugin-prismic-previews";

import linkResolver from "./src/utils/linkResolver";
import {lazy} from "react";

const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: {
      project: lazy(() => import('./src/templates/Project')),
    },
  },
]

export const wrapRootElement = ({element}) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    {element}
  </PrismicPreviewProvider>
);